import React, { useState } from "react";
import "./Staff/staff.css";

export const Staff = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalName, setModalName] = useState('');

  const openModal = (bio, name) => {
    setModalContent(bio);
    setModalName(name);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div id="staff" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Staff</h2>
        </div>
        <div id="row justify-content-center">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 staff">
                  <div className="thumbnail" onClick={() => openModal(d.bio, d.name)}>
                    <div className="staff-img-container">
                      <img src={d.img} alt={d.name} className="staff-img" />
                      <button className="info-button" onClick={(e) => {
                        e.stopPropagation();
                        openModal(d.bio, d.name);
                      }}>i</button>
                    </div>
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      <p>{d.title}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>

      {showModal ? (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">About {modalName}</h4>
              </div>
              <div className="modal-body">
                <p>{modalContent}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};