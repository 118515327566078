//src/modals/PoliciesModal.js
import React from 'react';
import './PoliciesModal.css';

const PoliciesModal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-title">{title}</div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default PoliciesModal;
