import React, { useState } from "react";
import emailjs from "emailjs-com";
import PoliciesModal from '../modals/PoliciesModal';
import "../modals/PoliciesModal.css";

const initialState = {
  name: "",
  email: "",
  message: "",
  interest: "",
};

export const Contact = (props) => {
  const [{ name, email, message, interest }, setState] = useState(initialState);
  const year = new Date().getFullYear();

  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message, interest);

    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <select
                    name="interest"
                    id="interest"
                    className="form-control"
                    value={interest}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Interest</option>
                    <option value="Camp">Camp</option>
                    <option value="Individual Practices">Individual Practices</option>
                    <option value="Group Practices (up to 10)">Group Practices (up to 10)</option>
                  </select>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={`https://web.whatsapp.com/${props.data ? props.data.whatsappNumber : ""}`}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {year} Dr. Performance Skills & Mindset | 
            <button className="link-button" onClick={() => setIsTermsOpen(true)}> Terms and Conditions </button> | 
            <button className="link-button" onClick={() => setIsPrivacyOpen(true)}> Privacy Policy </button>
          </p>
          <p>
            Design & Developed by <a href="https://www.linkedin.com/in/sevim-ezgi-bulamaci/" target="_blank" rel="noopener noreferrer">Sevim Ezgi Bulamacı</a>
          </p>
        </div>
      </div>

      <PoliciesModal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} title="Terms and Conditions">
        <p>Participating in Dr. Performance Basketball Camp involves certain inherent risks, including the potential for sustaining injuries. Your decision to participate in the camp is entirely at your own risk. Dr. Performance Basketball Camp, its organizers, coaches, volunteers, partners, and sponsors cannot be held liable in any way for any injuries, damages, and/or theft in any form that may occur during the camp.</p>
        <p>Dr. Performance Basketball Camp reserves the right to use and publish photos taken during the camp for promotional and informational purposes without the right to compensation for participants or others involved. By participating in the camp, you acknowledge and agree to this use of images.</p>
        <p>Additionally, Dr. Performance Basketball Camp reserves the right to refuse registrations without providing reasons.</p>
        <p>Your safety and well-being are of utmost importance to us. Please adhere to all camp rules, guidelines, and instructions provided by the organizers to ensure a safe and enjoyable experience for everyone.</p>
        <p>If you have any questions or concerns about this disclaimer, please contact us at info@drperformance.nl</p>
        <p>Thank you for your understanding and cooperation.</p>
        <p>© 2024/2025, Dr. Performance Basketball Camp. All rights reserved.</p>
        <button type="button" className="apply-button" onClick={() => setIsTermsOpen(false)}>Close</button>
      </PoliciesModal>

      <PoliciesModal isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} title="Privacy Policy">
        <p>Dr. Performance Basketball Camp collects necessary personal data from camp participants to efficiently organize the camp, establish agreements, and provide news updates and (future) invitations. Rest assured, your personal data will not be shared with third parties.</p>
        <p>Participation in Dr. Performance Basketball Camp requires the provision of certain personal information. If a participant chooses not to provide the necessary personal information, unfortunately, participation in the camp will not be possible.</p>
        <p>In accordance with legal requirements, participant data will be securely retained for a period of 7 years. Participants have the right to inspect, rectify, or delete their personal data (where possible within the law). Requests can be made by sending an email to info@drperformance.nl</p>
        <p>By participating in Dr. Performance Basketball Camp, you also grant the organization permission to publish photos of you on social media and/or the organization's website for promotional and informational purposes.</p>
        <p>Your privacy is important to us. If you have any concerns or questions regarding the handling of your personal data, please feel free to contact us.</p>
        <p>© 2024/2025, Dr. Performance Basketball Camp. All rights reserved.</p>
        <button type="button" className="apply-button" onClick={() => setIsPrivacyOpen(false)}>Close</button>
      </PoliciesModal>
    </div>
  );
};
