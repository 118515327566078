import React from "react";
import "./Schedule/schedule.css";

export const Schedule = (props) => {
  return (
    <div id="schedule" className="text-center">
      <div className="container">
        <div className="section-title text-center">
          <h2>Schedule</h2>
        </div>
        <div className="row schedule-content">
          <div className="col-md-6 schedule-details">
            <h3>Dates May 20th - June 28th (6 weeks)</h3>
            <ul>
              <li><b>Monday:</b> Positional Practice</li>
              <li><b>Wednesday:</b> Tactical Practice</li>
              <li><b>Friday:</b> Shooting Practice</li>
            </ul>
            <p>Individual Strength Training Program</p>
            <p>Nutrition & Diet Program</p>
          </div>

          <div className="col-md-6 pricing-additional">
            <div className="prices">
              <h4>Prices</h4>
              <p>499 € + BTW <i>(for Apollo Members)</i></p>
              <p>549 € +BTW <i>(non-Apollo Members)</i></p>
              <p><b>IBAN:</b> NL63 RABO 0369 7851 34</p>
            </div>

            <div className="additional-info">
              <h4>Additional</h4>
              <p>Individual Practices</p>
              <p>Group Practices (up to 10)</p>
              <a href="#contact" className="btn btn-custom page-scroll">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};