import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container video-container">
            <iframe
              title="Dr. Performance"
              src="https://www.youtube.com/embed/5uHU_df3Sfw?autoplay=1&controls=0&showinfo=0&loop=1&playlist=5uHU_df3Sfw"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video-iframe"
            />
          </div>
        </div>
      </div>
    </header>
  );
};
