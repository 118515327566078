import React from "react";
import "./About/about.css"

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="inspirational-quote">
          <h1>"THINK GREATER, BOUNCE HIGHER!"</h1>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/team.JPG" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              {props.data ? props.data.paragraph.map((d, i) => (
                <p key={`${d}-${i}`}>{d}</p>
              )) : "loading..."}
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    )) : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why2.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    )) : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="why-text">
              <h2>Why Dr. Performance?</h2>
              <ul>
                <li>36 hours of basketball skills & development</li>
                <li>Mental toughness & emotional intelligence seminar</li>
                <li>Individual training & strength program</li>
                <li>Athlete nutrition & diet program</li>
                <li>Individual SWOT Analysis</li>
                <li>Practice jerseys</li>
                <li>Individual meetings with parents</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};