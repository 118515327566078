import React, { useState } from "react";
import "../modals/registrationModal.css"
import { RegistrationModal } from "../modals/RegisterModal";

export const Navigation = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="img/logoWhite.PNG" alt="Logo" style={{ maxWidth: '100px', marginRight: '10px' }} />
            <a className="navbar-brand page-scroll" href="#page-top">
              DR. PERFORMANCE <br /> SKILLS & MINDSET
            </a>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll">
                About Us
              </a>
            </li>
            <li>
              <a href="#programs" className="page-scroll">
                Programs
              </a>
            </li>
            <li>
              <a href="#schedule" className="page-scroll">
                Schedule
              </a>
            </li>
            <li>
              <a href="#gallery" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>
              <a href="#staff" className="page-scroll">
                Staff
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li>
            <button className="apply-button" onClick={() => window.open('https://docs.google.com/forms/d/1vt3p645jeoePWZAfqQenUJAm5yGx34317a7J3DpsRQs/viewform?pli=1&pli=1&edit_requested=true', '_blank')}>Registration</button>
              {/*<button className="apply-button" onClick={() => setIsModalOpen(true)}>Registration</button>*/}
            </li>
          </ul>
        </div>
      </div>
      {isModalOpen && <RegistrationModal onClose={() => setIsModalOpen(false)} />}
    </nav>
  );
};